<template>
  <div class="page-wrapper">
    <section class="hero">
      <div class="img-wrap" data-inview="fadeInUp" data-delay="200">
        <img src="@/assets/img/hero.jpg" alt="Le Balt" />
      </div>
      <div class="data">
        <div data-inview="fadeInUp" data-delay="300">
          <h2 class="serif" v-html="$t('hero-1')"></h2>
          <h3 class="title" v-html="$t('hero-2')"></h3>
          <a v-scroll-to:-100 href="#form" class="cta">
            <p class="text">{{ $t('cta') }}</p>
          </a>
        </div>
      </div>
      <img class="circle-montreal" src="@/assets/img/circle-montreal.png" alt="Montréal" />
      <div class="circle-bottom-wrap">
        <img src="@/assets/img/half-circle.svg" alt="half circle" />
      </div>
    </section>

    <section id="commodites" class="arguments">
      <h2 class="medium-title" data-inview="fadeInUp" data-delay="200" v-html="$t('argument-title')"></h2>
      <div class="icon-wrap" data-inview="fadeInUp" data-delay="300">
        <img src="@/assets/img/icon-gym.svg" alt="Icon gym" />
        <p class="serif">{{ $t('argument-1') }}</p>
      </div>
      <!-- <div class="icon-wrap" data-inview="fadeInUp" data-delay="350">
        <img src="@/assets/img/icon-spas.svg" alt="Icon spa" />
        <p class="serif">{{ $t('argument-2') }}</p>
      </div> -->
      <div class="icon-wrap" data-inview="fadeInUp" data-delay="400">
        <img src="@/assets/img/icon-balcon.svg" alt="Icon balcon" />
        <p class="serif">{{ $t('argument-3') }}</p>
      </div>
      <div class="icon-wrap" data-inview="fadeInUp" data-delay="450">
        <img src="@/assets/img/icon-terrasse.svg" alt="Icon terrasse" />
        <p class="serif">{{ $t('argument-4') }}</p>
      </div>
      <div class="icon-wrap" data-inview="fadeInUp" data-delay="500">
        <img src="@/assets/img/icon-bbq.svg" alt="Icon bbq" />
        <p class="serif">{{ $t('argument-5') }}</p>
      </div>
    </section>

    <section class="texture">
      <img src="@/assets/img/texture.svg" alt="Texture" />
    </section>

    <section id="projet" class="description">
      <h2 class="medium-title" data-inview="fadeInUp" data-delay="200">{{ $t('description-title') }}</h2>
      <div class="bloc" data-inview="fadeInUp" data-delay="300">
        <img src="@/assets/img/emplacement.jpg" alt="Emplacement exceptionnel" />
        <h3 class="serif">{{ $t('description-1-title') }}</h3>
        <p class="text" v-html="$t('description-1-text')"></p>
      </div>
      <div class="bloc" data-inview="fadeInUp" data-delay="350">
        <img src="@/assets/img/design.jpg" alt="Design intemporel" />
        <h3 class="serif">{{ $t('description-2-title') }}</h3>
        <p class="text" v-html="$t('description-2-text')"></p>
      </div>
      <div class="bloc" data-inview="fadeInUp" data-delay="400">
        <img src="@/assets/img/bien-etre.jpg" alt="Bien-être optimisé" />
        <h3 class="serif">{{ $t('description-3-title') }}</h3>
        <p class="text" v-html="$t('description-3-text')"></p>
      </div>
      <div class="cta-wrap">
        <a v-scroll-to:-100 href="#form" class="cta">
          <p class="text">{{ $t('cta') }}</p>
        </a>
      </div>
      <div class="circle-left-wrap">
        <img class="circle-left" src="@/assets/img/circle-lines.svg" alt="Circle" />
      </div>
      <div class="circle-right-wrap">
        <img class="circle-right" src="@/assets/img/circle-lines-right.svg" alt="Circle" />
      </div>
    </section>

    <section id="emplacement" class="map-wrap">
      <div class="content">
        <address class="serif" data-inview="fadeInUp" data-delay="200">{{ $t('adresse') }}</address>
        <h2 class="medium-title" data-inview="fadeInUp" data-delay="300">{{ $t('adresse-title') }}</h2>
        <img class="map" src="@/assets/img/map.png" alt="google map" />
      </div>
      <img class="background" src="@/assets/img/background-curve.svg" alt="curves" />
    </section>

    <section id="form" class="form-wrap">
      <div class="form" data-inview="fadeInUp" data-delay="200" @submit="handleFormSubmit">
        <h3 class="medium-title form-title">{{ $t('form-title') }}</h3>

        <!-- <form action="">
          <input type="text" :placeholder="$t('form-prenom')" name="prenom" required />
          <input type="text" :placeholder="$t('form-nom')" name="nom" required />
          <input type="mail" :placeholder="$t('form-email')" name="email" required />
          <input type="text" :placeholder="$t('form-tel')" name="tel" required />
          <select name="chambres">
            <option value="">{{ $t('form-chambres') }}</option>
            <option v-for="(item, i) in $t('select-1')" :key="i" :value="item.value + '|' + item.truelabel">{{ item.label }}</option>
          </select>
          <select name="source">
            <option value="Source d’information">{{ $t('form-option-1') }}</option>
            <option v-for="(item, i) in $t('select-2')" :key="i" :value="item.value + '|' + item.truelabel">{{ item.label }}</option>
          </select>
          <textarea placeholder="Message" name="message"></textarea>
          <p class="text">{{ $t('form-requis') }}</p>
          <div class="submit-wrap">
            <input type="submit" :value="$t('form-send')" />
          </div>
        </form> -->

        <div id="hubspotFormFr" class="hubspotform" v-if="$route.meta.lang === 'fr'" v-once></div>
        <div id="hubspotFormEn" class="hubspotform" v-if="$route.meta.lang === 'en'" v-once></div>

      </div>
    </section>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import { saveForm } from '@/misc/firebase-init'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  data() {
    return {
      formMessage: '',
      isSending: false,
    }
  },
  head() {
    return {
      title: {
        inner: 'Le BALT',
        separator: '-',
        complement: this.$t(`meta-title`)
      },
      meta: [
        {
          name: 'description',
          content: this.$t(`meta-desc`),
          id: 'desc',
        },
        { name: 'application-name', content: this.$t(`meta-title`) },
        { name: 'twitter:title', content: this.$t(`meta-desc`) },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t(`meta-desc`),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`meta-title`) },
        { property: 'og:site_name', content: this.$t(`meta-title`) },
        {
          property: 'og:description',
          content: this.$t(`meta-desc`),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://balt.netlify.app/img/og.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://balt.netlify.app/img/og.jpg',
        },
      ],
    }
  },
  mounted() {
    const script = document.createElement("script");
    script.src="https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "44266199",
          formId: "93cfdc97-1e0b-4a4e-a80c-902bc631630d",
          target: "#hubspotFormFr"
        })
        window.hbspt.forms.create({
          portalId: "44266199",
          formId: "7b6e1916-4d53-4721-9f35-94f2071799c8",
          target: "#hubspotFormEn"
        })
      }
    })
  },
  watch: {
    $route: {
      async handler($route) {
        if ($route.hash) {
          setTimeout(() => {
            if ($route.hash === '#commodites') {
              const $target = document.querySelector('#commodites')
              $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }
            if ($route.hash === '#projet') {
              const $target = document.querySelector('#projet')
              $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }
            if ($route.hash === '#emplacement') {
              const $target = document.querySelector('#emplacement')
              $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }
            if ($route.hash === '#informations') {
              const $target = document.querySelector('#form')
              $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }
          }, 755)
        }
      },
      immediate: true,
    },
  },
  methods: {
    async handleFormSubmit(e) {
      e.preventDefault()

      if (this.isSending) return
      this.isSending = true

      // Send form data to Lasso CRM
      const form = document.querySelector('.form form')
      const chambres = form.querySelector('select[name="chambres"]').value.split('|')
      const source = form.querySelector('select[name="source"]').value.split('|')
      const data = {
        prenom: form.querySelector('input[name="prenom"]').value,
        nom: form.querySelector('input[name="nom"]').value,
        email: form.querySelector('input[name="email"]').value,
        tel: form.querySelector('input[name="tel"]').value,
        message: form.querySelector('textarea[name="message"]').value,
        chambresValue: parseInt(chambres[0], 10),
        chambresLabel: chambres[1],
        sourceValue: parseInt(source[0], 10),
        sourceLabel: source[1],
      }

      try {
        await saveForm(data)

        console.log('📈 Datalayer push()')
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'formSubmission',
          category: 'LeBalt',
          action: 'send',
          label: 'form',
        })
        console.log('📈 Datalayer Done!')

        // await sendFormToLassoCrm(data)
        this.formMessage = 'success'
        // this.isSending = false
        document.querySelector('.form-title').innerHTML = this.$t('form-message')
        document.querySelector('.submit-wrap').classList.add('is-send')
        document.querySelector('.submit-wrap type="submit"').setAttribute('disabled', 'disabled')
        form.reset()
      } catch (error) {
        this.formMessage = error
        this.isSending = false
        form.reset()
      }
    },
  },
  computed: mapState('app', ['appTitle']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
