// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, addDoc, collection } from 'firebase/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB2b_-I_6wadYagGRq97_8Z2RiQ1lNqSfU',
  authDomain: 'jnv-web.firebaseapp.com',
  databaseURL: 'https://jnv-web.firebaseio.com',
  projectId: 'jnv-web',
  storageBucket: 'jnv-web.appspot.com',
  messagingSenderId: '21527426856',
  appId: '1:21527426856:web:6d4afff195d3550261021d',
  measurementId: 'G-67YC6X3Z82',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const functions = getFunctions(app, 'us-central1')
const sendFormToLassoCrm = httpsCallable(functions, 'sendFormToLassoCrm')

async function saveForm(form) {
  try {
    await addDoc(collection(db, 'forms'), form)

    // const data = form
    // await sendFormToLassoCrm(data)

    return form
  } catch (e) {
    console.error('Error adding document: ', e)
    return false
  }
}

export { saveForm, sendFormToLassoCrm }
