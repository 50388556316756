<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import { isNil } from 'lodash'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NavBar, FooterBar },

  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.meta.lang === 'en') {
            this.$i18n.locale = 'en'
          } else {
            this.$i18n.locale = 'fr'
          }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
  @import '@/theme/variables.scss';
  @import '@/theme/header.scss';
  @import '@/theme/landing.scss';
  @import '@/theme/footer.scss';
  @import '@/theme/animations-layer.scss';

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-weight: 300;
    color: $color-1;
    background-color: $color-2;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
</style>
