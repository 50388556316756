const trans = {
  fr: {
    'header-top-title': 'Condos urbains locatifs<br>au cœur de Montréal',

    cta: 'Inscrivez-vous',

    'hero-1': 'Aimes-tu la ville comme moi&nbsp;?',
    'hero-2': 'Choisir <span>la ville</span> <br>Choisir <span>le BALT</span>',

    'argument-title': 'Des essentiels pour votre <br>bien-être au quotidien',
    'argument-1': "Salle d'entraînement",
    'argument-2': 'Spas',
    'argument-3': 'Balcons privés',
    'argument-4': 'Terrasse sur le toit',
    'argument-5': 'BBQ',

    'description-title': 'Une vie urbaine inspirante',
    'description-1-title': 'Emplacement exceptionnel',
    'description-1-text':
      'Le meilleur de la vie urbaine montréalaise. Tout près du Vieux-Montréal et à quelques pas du Quartier des spectacles, vous serez inspirés par l’effervescence du secteur. Profitez de la proximité aux lignes de transports collectifs, de l’accès à tous les services et d’un mode de vie actif et stimulant.',
    'description-2-title': 'Design intemporel',
    'description-2-text':
      'Votre espace de vie au cœur de l’action vous offre tout ce dont vous avez besoin, sans superflu ni espace perdu&nbsp;:&nbsp;balcon privé, mobilier intégré, grande luminosité. Une architecture épurée misant sur la fonctionnalité, le design et le confort.',
    'description-3-title': 'Bien-être optimisé',
    'description-3-text':
      'Les aires communes ont été conçues avec minutie pour que vous profitiez autant de la vie que de la ville&nbsp;:&nbsp;terrasse sur le toit avec BBQ, vue incomparable sur Montréal, spas et salle d’entraînement accessibles en toutes saisons.',

    adresse: '333, boul. René-Lévesque Est, Montréal',
    'adresse-title': "À quelques pas de l'action",

    'form-title': 'Soyez parmi les premiers informés',
    'form-prenom': 'Prénom *',
    'form-nom': 'Nom *',
    'form-email': 'Courriel *',
    'form-tel': 'Téléphone *',
    'form-chambres': 'Nombre de chambre(s)',
    'form-chambres-1': '1 chambre',
    'form-chambres-2': '2 chambres',
    'form-chambres-3': '3 chambres',
    'form-option-1': 'Comment avez-vous entendu parler de nous?',
    'form-option-2': 'Médias Sociaux',
    'form-option-3': 'Annonces Facebook / Instagram',
    'form-option-4': 'Annnonces Google Ads',
    'form-option-5': 'Recherche Google',
    'form-option-6': 'Du Proprio',
    'form-option-7': 'Guide Habitation',
    'form-option-8': 'Affichage extérieur',
    'form-option-9': 'Référence amis ou famille',
    'form-option-10': 'Autres',
    'form-send': 'Envoyer',
    'form-requis': '*Champs obligatoires',

    'form-message': 'Merci! <br/> Votre inscription a bien été reçue.',

    'meta-title': 'Condos urbains locatifs au cœur de Montréal',
    'meta-desc': 'Votre espace de vie au cœur de l’action vous offre tout ce dont vous avez besoin : balcon privé, mobilier intégré, grande luminosité.',

    'footer-bureau': 'Adresse du BALT',
    'footer-partenaires': 'Une réalisation de',

    'select-1': [
      {
        value: 'Studio',
        label: 'Studio',
        truelabel: 'Studio',
      },
      {
        value: '337839',
        label: '1 chambre',
        truelabel: '1 chambre',
      },
      {
        value: '337838',
        label: '2 chambres',
        truelabel: '2 chambres',
      },
      {
        value: '337837',
        label: '3 chambres',
        truelabel: '3 chambres',
      },
    ],

    'select-2': [
      {
        value: '337833',
        label: 'Référence amis ou famille',
        truelabel: 'Référence amis ou famille',
      },
      {
        value: '337832',
        label: 'Médias Sociaux',
        truelabel: 'Médias Sociaux',
      },
      {
        value: '337831',
        label: 'Annonces Facebook',
        truelabel: 'Annonces Facebook',
      },
      {
        value: '337830',
        label: 'Du Proprio',
        truelabel: 'Du Proprio',
      },
      {
        value: '337829',
        label: 'Annonces Google Ads',
        truelabel: 'Annonces Google Ads',
      },
      {
        value: '337828',
        label: 'Guide Habitation',
        truelabel: 'Guide Habitation',
      },
      {
        value: '337827',
        label: 'Affichage extérieur',
        truelabel: 'Affichage extérieur',
      },
      {
        value: '337826',
        label: 'Recherche Google',
        truelabel: 'Recherche Google',
      },
      {
        value: '337825',
        label: 'Autres',
        truelabel: 'Autres',
      },
    ],
  },
  en: {
    'header-top-title': 'Urban rental condos<br>in the heart of Montreal',

    cta: 'Subscribe',

    'hero-1': 'Love the excitement of urban living?',
    'hero-2': 'Choose <span>the city</span> <br>Choose <span>le BALT</span>',

    'argument-title': 'Fully equipped for your lifestyle',
    'argument-1': 'Fitness centre',
    'argument-2': 'Hot tubs',
    'argument-3': 'Private balconies',
    'argument-4': 'Rooftop patio',
    'argument-5': 'BBQ',

    'description-title': 'City living at its finest',
    'description-1-title': 'Exceptional location',
    'description-1-text':
      "Enjoy the best of downtown Montreal. Living near Old Montreal and Quartier des spectacles, you'll find inspiration in the vibrant neighbourhood that surrounds you. The area is perfect for anyone who wants to be close to the action and enjoy convenient access to services and public transportation.",
    'description-2-title': 'Intelligent design, timeless features',
    'description-2-text':
      'Your centrally located home comes with everything you need—private balcony, built-in furniture, plenty of light—without excesses or wasted space. The streamlined architecture balances functionality, style and comfort.',
    'description-3-title': 'Optimized amenities',
    'description-3-text':
      'The common areas were designed to capitalize on the urban setting, from the rooftop patio with a barbecue station and outstanding views of Montreal, to the fitness centre and hot tubs open year-round.',

    adresse: '333 Boulevard René-Lévesque Est, Montreal',
    'adresse-title': 'Steps away from the action',

    'form-title': 'Be the first to know',
    'form-prenom': 'First name*',
    'form-nom': 'Last name*',
    'form-email': 'Email*',
    'form-tel': 'Phone number*',
    'form-chambres': 'Number of bedrooms',
    'form-chambres-1': '1 bedroom',
    'form-chambres-2': '2 bedrooms',
    'form-chambres-3': '3 bedrooms',
    'form-option-1': 'How did you hear about us?',
    'form-option-2': 'Social media',
    'form-option-3': 'Facebook/Instagram ads',
    'form-option-4': 'Google ads',
    'form-option-5': 'Google search',
    'form-option-6': 'Du Proprio',
    'form-option-7': 'Guide Habitation',
    'form-option-8': 'Outdoor advertising',
    'form-option-9': 'Friends or family',
    'form-option-10': 'Other',
    'form-send': 'Submit',
    'form-requis': '*Required fields',

    'form-message': 'Thank you! <br/> Your registration has been received.',

    'meta-title': 'Urban rental condos in the heart of Montreal',
    'meta-desc': 'Your centrally located home comes with everything you need—private balcony, built-in furniture, plenty of light—without excesses or wasted space.',

    'footer-bureau': 'BALT Address',
    'footer-partenaires': 'Developed by ',

    'select-1': [
      {
        value: 'Studio',
        label: 'Studio',
        truelabel: 'Studio',
      },
      {
        value: '337836',
        label: '1 bedroom',
        truelabel: '1 bedroom',
      },
      {
        value: '337835',
        label: '2 bedrooms',
        truelabel: '2 bedrooms',
      },
      {
        value: '337834',
        label: '3 bedrooms',
        truelabel: '3 bedrooms',
      },
      {
        value: '337834',
        label: '3 bedrooms',
        truelabel: '3 bedrooms',
      },
    ],

    'select-2': [
      {
        value: '337766',
        label: 'Recommended by a friend',
        truelabel: 'Recommended by a friend',
      },
      {
        value: '337832',
        label: 'Social media',
        truelabel: 'Médias Sociaux',
      },
      {
        value: '337831',
        label: 'Facebook ads',
        truelabel: 'Annonces Facebook',
      },
      {
        value: '337830',
        label: 'Du Proprio',
        truelabel: 'Du Proprio',
      },
      {
        value: '337829',
        label: 'Google ads',
        truelabel: 'Annonces Google Ads',
      },
      {
        value: '337828',
        label: 'Guide Habitation',
        truelabel: 'Guide Habitation',
      },
      {
        value: '337827',
        label: 'Outdoor advertising',
        truelabel: 'Affichage extérieur',
      },
      {
        value: '337826',
        label: 'Google search',
        truelabel: 'Recherche Google',
      },
      {
        value: '337825',
        label: 'Other',
        truelabel: 'Autres',
      },
    ],
  },
}

export default trans
